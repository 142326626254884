import { TicketType } from '../../types/ticketType';

import { StoreAction, TicketsReducerType } from '../redux.interface';

export const setAllTickets = (tickets: TicketType[]) => ({
  type: 'tickets/SET_ALL_TICKETS',
  payload: tickets,
});
export const resetAllTickets = () => ({
  type: 'tickets/RESET_ALL_TICKETS',
});
export const setCurrentDeviceTickets = (tickets: TicketType[]) => ({
  type: 'tickets/SET_CURRENT_DEVICE_TICKETS',
  payload: tickets,
});
export const resetCurrentDeviceTickets = () => ({
  type: 'tickets/RESET_CURRENT_DEVICE_TICKETS',
});

const initialState: TicketsReducerType = {
  allTickets: null,
  currentDeviceTickets: null,
};

export default function specificRulesReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case 'tickets/SET_ALL_TICKETS':
      const allTickets = action.payload.sort(function (a: any, b: any) {
        return a.time.getTime() - b.time.getTime();
      });
      return {
        ...state,
        allTickets,
      };
    case 'tickets/RESET_ALL_TICKETS':
      return {
        ...state,
        allTickets: initialState.allTickets,
      };
    case 'tickets/SET_CURRENT_DEVICE_TICKETS':
      const currentDeviceTickets = action.payload;
      return {
        ...state,
        currentDeviceTickets,
      };
    case 'tickets/RESET_CURRENT_DEVICE_TICKETS':
      return {
        ...state,
        currentDeviceTickets: initialState.currentDeviceTickets,
      };
    default:
      return state;
  }
}
